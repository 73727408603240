<template>
	<div v-if="canRestore">
		<v-flex v-if="simpleButtonDisplay && !listTileDisplay" shrink>
			<w-btn flat icon="restore" :loading="isLoading" mini @click="restore()">
				{{ $tc('documents.restore_documents', value.length) }}
			</w-btn>
		</v-flex>
		<v-list-tile v-else-if="listTileDisplay" avatar @click="restore()">
			<v-list-tile-avatar>
				<v-progress-circular v-if="isLoading" color="primary" indeterminate />
				<v-icon v-else color="primary">restore</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title>{{ $tc('documents.restore_documents', value.length) }}</v-list-tile-title>
			</v-list-tile-content>
		</v-list-tile>
	</div>
</template>

<script>
import { mapState } from 'vuex'

import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'

export default {
	name: 'RestoreFromTrashButton',
	mixins: [DocumentActionModuleGuard],
	props: {
		value: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data: function () {
		return {
			isLoading: false
		}
	},
	computed: {
		...mapState({
			isGuest: state => state.company.userRoles.isGuest
		}),
		canRestore: function () {
			return !this.isGuest && this.value.length > 0 && this.value.every(node => node.deleted_at != null && !node.is_folder)
		}
	},
	methods: {
		restore: function () {
			let result = Promise.resolve()
			if (this.canRestore) {
				this.isLoading = true
				const trashFolder = this.service.findNodeInStructure('trash')
				const promises = this.value.map(node => {
					return this.service.updateDocument(this.vendorId, node, null, {deleted_at: null})
						.then(() =>{
							if (trashFolder) {
								trashFolder.remove(node)
							}
						})
				})
				this.eventBus.emit(this.events.DELETION_STARTED)
				result = Promise.all(promises)
					.then(() => {
						this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$tc('documents.messages.document_restored', this.value.length))
					})
					.finally(() => {
						this.isLoading = false
						this.eventBus.emit(this.events.DELETION_ENDED)
					})
			}
			return result
		}
	}
}
</script>
